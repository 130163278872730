'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

const PageviewEvent = () => {
  const pathname = usePathname();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'next-pageview',
        page: pathname
      });
    }
  }, [pathname]);

  return null;
};

export default PageviewEvent;
